import React, { useState, useEffect, useContext, useRef } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import "./Tabs.scss";
import {
  Countdown,
  QnaContainer,
  VotingContainer,
} from "react-vm-component-library";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Modal } from "../modal/Modal";
import QnAContext from "../../context/QnAContext";
import {
  checkTabs,
  getCurrentQs,
  paramsDecrypt,
  sentMatomo,
} from "../../utils/Helper";
import { useHttp } from "../../hooks/use-https";
import routes from "../../constants/routes.json";
import { getVmBaseUrl } from "../../api/apiHelper";
import StreamContent from "../streamContent/StreamContent";
import { getEventId } from "../../utils/QueryString";
import Header from "../header/Header";
import TabMenu from "./TabMenu";
import EvaluationContent from "../evaluation/EvaluationContent";
import TabMsg from "./TabMsg";
import ChimeAudioLink from "../streamContent/ChimeAudioLink";
import SocketContainer from "./SocketContainer";

const APP_CONTEXT = "qna";
const SessionInteractionPage = ({
  isLoggedIn,
  setIsLoggedIn,
  encryptMode,
  setIsLoadingLogin,
  ...rest
}) => {
  const [activeTab, setActiveTab] = useState("qna");
  const [loginModalOpen, setLoginModalOpen] = useState(true);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const [session, setSession] = useState(null);
  const [isFetchingTime, setIsFetchingTime] = useState(true);
  const [expired, setExpired] = useState(false);
  const [modalText, setModalText] = useState({});
  const [votingCallback, setVotingCallBack] = useState(false);
  const [qnaNumberCount, setQnaNumberCount] = useState();
  const [isQnaEmojiActive, setQnaEmojiActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  console.log(location?.state);
  const {
    QnaConfig,
    qnaText,
    votingConfig,
    setQnaConfig,
    specificSettings,
    streamConfig,
    qnaForceLoginMode,
    anonymLoginMode,
    expiredMode,
    evaluationConfig,
    timeZoneStr,
    countdownConfig,
    text,
    loginBroadcasterConfig,
  } = useContext(QnAContext);

  const [streamSetting, setStreamSetting] = useState(streamConfig);
  const sendToEmojiChannelRef = useRef(null); //ref from a child component

  const navigate = useNavigate();
  const params = useParams();
  const httpHook = useHttp();
  const sid = paramsDecrypt(params.id, encryptMode);

  useEffect(() => {
    if (isLoggedIn) {
      setQnaConfig((prevState) => ({
        ...prevState,
        commentLikes: true,
      }));
    }

    if (!isLoggedIn) {
      setQnaConfig((prevState) => ({
        ...prevState,
        commentLikes: false,
      }));
    }
  }, [isLoggedIn]);

  const fetchingData = () => {
    const id = sid;
    const url = `https://${getVmBaseUrl()}/v1/contentsessions/${id}.json?include_contents=0&include_resourcegroups=0&include_resourcegroups_body=0&include_contentsessioncomments=0&include_translationkey_mappings=0&include_translationkeys=0&include_translations=0&translationkey_mappings_smart_find=0&include_tags=0&include_time_to_start=0&use_cache=0`;
    httpHook
      .fetchData({ url: url }, (pData) => {
        setSession(pData);
        if (pData?.room?.streaming_urls.length > 0) {
          const streamUrlIndex = pData.room.streaming_urls.length > 1 ? 1 : 0;
          setStreamSetting(
            _.merge(streamConfig, {
              playerConfig: {
                streamUrl: pData?.room?.streaming_urls[streamUrlIndex],
              },
            })
          );
        }
      })
      .then(setIsFetchingTime(false));
  };

  useEffect(() => {
    const indexOfQna = specificSettings.availableTabs.indexOf("qna");

    if (indexOfQna !== 0) {
      setLoginModalOpen(false);
    }

    if (isFetchingTime) {
      fetchingData();
    }
    // fetchingData();

    // Check config if session end show evaluation tab as default
    const now = moment(new Date().getTime()).tz(timeZoneStr);
    const end = moment(session?.ends_at_local).tz(timeZoneStr);
    const timeDiff = end.diff(moment(now), "seconds");
    let tabs = [];
    if (
      specificSettings?.tabsAfterSessionEnd?.active && timeDiff < 0
    ) {
      tabs = [...specificSettings.tabsAfterSessionEnd.availableTabs];
    } else {
      tabs = [...specificSettings.availableTabs];
    }

    // Due to the new function checking the allowed tabs in api call, the initial active tab logic should be adjust
    if (specificSettings.sessionbasedDynamicTabs) {
      for (let i = 0; i < tabs.length; i++) {
        let apiKeyAllowedValue = false;
        if (tabs[i] === "qna") apiKeyAllowedValue = session?.qna_allowed;
        if (tabs[i] === "polling") apiKeyAllowedValue = session?.voting_allowed;
        if (tabs[i] === "evaluation")
          apiKeyAllowedValue = session?.evaluation_allowed;
        if (apiKeyAllowedValue) {
          setActiveTab(tabs[i]);
          break;
        }
      }
    } else {
      setActiveTab(tabs[0]);
    }

    if (!specificSettings.qnaForceLogin) {
      setQnaConfig((prevState) => ({
        ...prevState,
        isUserNameWriteable: true,
      }));

      if (specificSettings.showLogin) {
        setQnaConfig((prevState) => ({
          ...prevState,
          isChairTable: false,
        }));
        // setMode("anonymLoginMode");
        setModalText(anonymLoginMode);
      }

      if (!specificSettings.showLogin) {
        setQnaConfig((prevState) => ({
          ...prevState,
          isChairTable: false,
        }));
        // setMode("anonymMode");
        setModalText(null);
      }
    }
    if (specificSettings.qnaForceLogin) {
      if (QnaConfig.userObject.user_id !== null) {
        setQnaConfig((prevState) => ({
          ...prevState,
          isChairTable: false,
        }));
      } else {
        // setMode("qnaForceLoginMode");
        setModalText(qnaForceLoginMode);
        setQnaConfig((prevState) => ({
          ...prevState,
          isChairTable: true,
        }));
      }
    }

    setLoading(false);

    return () => {
      setIsFetchingTime(true);
    };
  }, [session]);

  useEffect(() => {
    if (!isFetchingTime) {
      //const end = moment.utc(sessionEnd).format();
      // const end = onSiteMoment(sessionEnd, timeZoneStr)
      const end = moment(session?.ends_at_local).tz(timeZoneStr);
      const countDown = setInterval(() => {
        const now = moment(new Date().getTime()).tz(timeZoneStr);

        const timeDiff = end.diff(moment(now), "minutes");
        //  const timeDiff = -1

        console.log(timeDiff, "minutes");

        if (timeDiff <= 0) {
          setExpired(true);
          setExpiredModalOpen(true);
        }
      }, 60000);
      return () => {
        clearInterval(countDown);
      };
    }

    return () => clearInterval();
  }, [isFetchingTime, session, timeZoneStr]);

  //set activeTab if set via location.state
  useEffect(() => {
    if (
      isFetchingTime &&
      specificSettings.availableTabs.includes("evaluation") &&
      location?.state?.showEvalTab
    )
      setActiveTab("evaluation");
  }, [location?.state?.showEvalTab, isFetchingTime]);

  const backToRoom = () => {
    if (specificSettings.showRoomOverview) {
      navigate({
        pathname: routes.HOME,
        search: getCurrentQs(),
      });
    } else {
      navigate({
        pathname: routes.SESSIONEXPIRED,
        search: getCurrentQs(),
      });
    }
  };

  const LogIn = () => {
    // safeSessionId({ currentSessionId, eventId });
    sentMatomo(`${getEventId()}`, "User Login Session", `${sid}`);
    if (specificSettings.defaultLoginEntrance === "eanCongressLogin") {
      window.location.replace(
        // `http://staging.ean.virtual-meeting.net/#qna/contentsessions/${sid}`
        "https://ean-id.m-anage.com/Account/LoginSalesforce?context=VM&shortname=ean2024&language=en-GB&context=VM"
      );
    } else {
      navigate({
        pathname: "/auth/login",
        search: getCurrentQs(),
      });
    }
  };

  const isTabActiveCheck = (tabName) => activeTab === tabName;
  const emojiChannelName = `emojis_broadcaster_session_${session?.id}`;

  const checkShowBeforeLogin = (tabName) => {
    const indexOfTabName = specificSettings?.showAfterLogin.indexOf(tabName);
    if (indexOfTabName !== -1) return true;

    return false;
  };

  const handleEmojiSend = (emoji) => {
    if (sendToEmojiChannelRef.current) {
      sendToEmojiChannelRef.current(emojiChannelName, {
        message: emoji,
        timestamp: Date.now(),
      });
    }
  };

  if (isFetchingTime && loading) {
    return <div>Loading...</div>;
  }

  const isRoomInStreamSettings =
    streamSetting?.active &&
    streamSetting?.roomsIds.includes(session?.room?.id);

  const isStreamVisible =
    isRoomInStreamSettings &&
    streamSetting?.type === "stream" &&
    session?.room?.streaming_urls?.length > 0;

  const isAudioOnly =
    isRoomInStreamSettings && streamSetting?.type === "chimeAudioOnly";

  const isModalDataAvailable =
    !isLoggedIn &&
    !!modalText?.title &&
    !!modalText?.button1 &&
    !!modalText?.button2;
  return (
    <div className="Tabs container">
      {specificSettings.qnaTopImageHolder && (
        <div className="Tabs__imgHolder">
          <img src={specificSettings.qnaTopImageHolder} alt="" />
        </div>
      )}
      {isQnaEmojiActive && (
        <SocketContainer
          socketUrl={loginBroadcasterConfig?.socketUrl}
          channelName={emojiChannelName}
          onSendToChannelRef={sendToEmojiChannelRef}
        />
      )}

      <Header
        specificSettings={specificSettings}
        sid={sid}
        text={text}
        isLoggedIn={isLoggedIn}
        setQnaConfig={setQnaConfig}
        setIsLoggedIn={setIsLoggedIn}
        setIsLoadingLogin={setIsLoadingLogin}
        navigate={navigate}
        onBackToRooms={() => backToRoom()}
        onLogin={LogIn}
        {...rest}
      />

      {isAudioOnly && (
        <ChimeAudioLink
          streamSetting={streamSetting}
          session={session}
          txtOpenStream={streamConfig?.txtOpenStream}
        />
      )}
      {isStreamVisible && <StreamContent {...streamSetting} />}

      {specificSettings.showSessionTitle && (
        <div className="title">
          <span>{session?.title}</span>
        </div>
      )}

      {expired && expiredModalOpen && (
        <Modal
          text={expiredMode}
          setLoginModalOpen={setExpiredModalOpen}
          specificSettings={specificSettings}
          action={backToRoom}
        />
      )}

      {loginModalOpen && isModalDataAvailable && (
        <Modal
          text={modalText}
          setLoginModalOpen={setLoginModalOpen}
          specificSettings={specificSettings}
          action={LogIn}
        />
      )}

      <div
        style={
          (loginModalOpen && isModalDataAvailable) || expiredModalOpen
            ? { opacity: "10%" }
            : {}
        }
      >
        <TabMenu
          specificSettings={specificSettings}
          qnaNumberCount={qnaNumberCount}
          session={session}
          isLoggedIn={isLoggedIn}
          loginModalOpen={loginModalOpen}
          votingCallback={votingCallback}
          setLoginModalOpen={setLoginModalOpen}
          isTabActive={isTabActiveCheck}
          setActiveTab={setActiveTab}
          setVotingCallBack={setVotingCallBack}
        />

        {specificSettings.showDurationProgress && (
          // <DurationProgress durationSec={timeDiff} />
          <Countdown
            ends_at={session?.ends_at_local}
            starts_at={session?.starts_at_local}
            timeZoneStrOnSite={timeZoneStr}
            {...countdownConfig}
          />
        )}

        <div className="Tabs__outlet">
          {checkShowBeforeLogin("qna") &&
          specificSettings.qnaForceLogin &&
          !isLoggedIn ? (
            <TabMsg type="flex" hide={isTabActiveCheck("qna")}>
              {qnaText.showAfterLoginText}
            </TabMsg>
          ) : (
            <QnaContainer
              session_id={sid}
              base_api={getVmBaseUrl()}
              show={isTabActiveCheck("qna") ? true : false}
              qnaConfig={QnaConfig}
              qnaText={qnaText}
              appContext={APP_CONTEXT}
              onCommentsCount={(e) => setQnaNumberCount(e)}
              onEmojiAction={(emoji) => handleEmojiSend(emoji)}
              onEmojiActive={(state) => setQnaEmojiActive(state)}
              showSessionTitle={QnaConfig.showSessionTitle}
              showInfoTitle={QnaConfig?.showInfoTitle || false}
            />
          )}
          <VotingContainer
            session_id={sid}
            socketBaseUrl={getVmBaseUrl()}
            votingText={{ ...votingConfig.votingText }}
            votingConfig={{ ...votingConfig.votingConfig }}
            show={isTabActiveCheck("polling") ? true : false}
            appContext={APP_CONTEXT}
            user={
              QnaConfig.userObject.user_id === null
                ? null
                : { user_id: QnaConfig.userObject.user_id }
            }
            onVotingStatusChange={(e) => setVotingCallBack(e)}
            onConnectionReload={() => window.location.reload()}
            onConnectionErrorShowButton={true}
          />

          <EvaluationContent
            session={session}
            evaluationConfig={evaluationConfig}
            userObject={QnaConfig?.userObject}
            timeZoneStr={timeZoneStr}
            showMustBeLoggedIn={
              checkShowBeforeLogin("evaluation") &&
              specificSettings.qnaForceLogin &&
              !isLoggedIn
            }
            checkShowBeforeLogin={checkShowBeforeLogin}
            isTabActiveCheck={isTabActiveCheck}
          />
        </div>
      </div>
    </div>
  );
};

export default SessionInteractionPage;
